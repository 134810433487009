<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import apiTool from '@/command/apiTool'
import { getAction } from '@/command/netTool'
import CardIconLabelLocal from '../../../components/CardIconLabelLocal'
import { bedTypeList } from '@/utils/textFile'

export default {
  name: 'liveDetail',
  data() {
    return {
      ...api.command.getState(),
      // 主体信息类型
      mainBodyType: [],
      labelGroup: [],
      detail: {
        salePrice: null,
        vipPrice: null,
        canBuy: '1'
      },
      isShowSnapshot: false
    }
  },
  watch: {
    'detail.mainBodyId'(a, b) {
      if (a) {
        this.getBaseDataInit(a)
        this.getMainBody(a)
      }
    }
  },
  mounted() {
    const { id } = this.$route.query
    // 获取详情
    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/goods/farmHomeStay/detail?id=${id}`
        })
        .then(result => {
          api.command.getLabelCodeGroup.call(this, {
            url: '/api/base/farmLabelData/getByCodeSelf',
            params: {
              id: id,
              codes: [
                {
                  key: 'farmhouse_food_safety'
                },
                {
                  key: 'farmhouse_bathroom'
                },
                {
                  key: 'farmhouse_room'
                }
              ]
            }
          })
          this.$forceUpdate()
        })
    } else {
      api.command.getLabelCodeGroup.call(this, {
        url: '/api/base/farmLabelData/getByCodeSelf',
        params: {
          codes: [
            {
              key: 'farmhouse_food_safety'
            },
            {
              key: 'farmhouse_bathroom'
            },
            {
              key: 'farmhouse_room'
            }
          ]
        }
      })
    }
    this.onMainBodySearch()
  },
  methods: {
    getMainBody(id) {
      getAction(`/api/baseMonitor/queryBaseMonitorList?shopId=${id}`).then(e => {
        this.isShowSnapshot = e.data.length > 0 ? true : false
      })
    },
    async onMainBodySearch(value = '') {
      const searchResult = await api.command.searchPage.call(this, {
        url: `/api/commodity/farmMainBody/page?mainBodyName=${value}`
      })
      this.mainBodyType = searchResult.map(el => {
        return {
          name: el.mainBodyName,
          value: el.id
        }
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称(76字符内)',
            type: 'input',
            cols: 12,
            key: 'name',
            props: {
              maxLength: 76
            },
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '关联店铺',
            type: 'dropDownInput',
            cols: 12,
            gutter: 3,
            key: 'mainBodyName',
            valueKey: 'mainBodyId',
            onInputSearch: this.onMainBodySearch,
            inputProps: {
              placeholder: '搜索店铺',
              addonBefore: <a-icon type="search" />
            },
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '每天可订数量(间)',
            type: 'inputNumber',
            cols: 12,
            gutter: 10,
            key: 'stockNum',
            rules: [
              {
                required: true,
                type: 'number'
              }
            ],
            props: {
              min: 0
            }
          },
          {
            name: '房间面积（m²）',
            type: 'row',
            cols: 12,
            gutter: 3,
            children: [
              {
                type: 'inputNumber',
                cols: 12,
                label: '最低面积',
                key: 'minArea',
                props: {
                  min: 0
                }
              },
              {
                type: 'inputNumber',
                cols: 12,
                label: '最高面积',
                key: 'maxArea',
                props: {
                  min: 0
                }
              }
            ]
          },
          {
            name: '窗户',
            type: 'row',
            cols: 6,
            children: [
              {
                type: 'radioButton',
                cols: 24,
                key: 'windowState',
                typeData: [
                  {
                    name: '有',
                    value: '0'
                  },
                  {
                    name: '无',
                    value: '1'
                  }
                ]
              }
            ]
          },
          {
            name: '早餐',
            type: 'row',
            cols: 6,
            children: [
              {
                type: 'radioButton',
                key: 'breakfastState',
                cols: 24,
                typeData: [
                  {
                    name: '有',
                    value: '0'
                  },
                  {
                    name: '无',
                    value: '1'
                  }
                ]
              }
            ]
          },
          {
            name: '床类型',
            type: 'row',
            cols: 6,
            children: [
              {
                type: 'radioButton',
                key: 'bedType',
                cols: 24,
                typeData: bedTypeList
              }
            ]
          },
          {
            name: 'Banner选择',
            type: 'radioButton',
            key: 'bannerType',
            cols: 12,
            defaultValue: '0',
            typeData: [
              {
                name: 'banner图片',
                value: '0',
                display: true
              },
              {
                name: '视频地址',
                value: '2',
                display: true
              },
              {
                name: '数字基地监控视频',
                value: '1',
                display: this.isShowSnapshot
              }
            ].filter(e => e.display),
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '开放购买（默认选择是）',
            type: 'radioButton',
            key: 'canBuy',
            cols: 12,
            typeData: [
              {
                name: '是',
                value: '1'
              },
              {
                name: '否',
                value: '0'
              }
            ],
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '周边推荐（默认选择否）',
            type: 'radioButton',
            key: 'recommend',
            defaultValue: '0',
            cols: 12,
            typeData: [
              {
                name: '是',
                value: '1'
              },
              {
                name: '否',
                value: '0'
              }
            ],
            rules: [
              {
                required: true
              }
            ]
          }
        ]
      }
    },
    getFormBox() {
      return {
        type: 'cardFormBox',
        children: [this.getForm2(), this.getForm23()]
      }
    },
    getForm2() {
      return {
        type: 'cardForm',
        title: () => (
          <div>
            <span style={{ color: '#f5222d' }}>*</span>Banner（尺寸750*420PX）限制1张
          </div>
        ),
        data: [
          {
            type: 'iconUpload',
            cols: 12,
            typeData: [
              {
                maxLength: 1,
                key: 'img',
                desc: '<br /> 支持格式.png/.jpg'
              }
            ],
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string'
              }
            ]
          }
        ]
      }
    },
    getForm23() {
      return {
        type: 'cardForm',
        title: '宣传视频',
        data: [
          {
            type: 'iconUploadVideo',
            cols: 12,
            typeData: [
              {
                maxLength: 1,
                key: 'videoUrl',
                desc: '视频上传尺寸<20M <br /> 支持格式.mov/.mp4/.wmv/.avi'
              }
            ]
          }
        ]
      }
    },
    getForm2Copy() {
      return {
        type: 'cardForm',
        title: '数字基地监控视频',
        data: [this.isShowSnapshot ? this.getBaseDataInit(this.detail.mainBodyId) : {}]
      }
    },
    getBaseDataInit(shopId = null) {
      return {
        type: 'baseUpload',
        key: 'monitorCode',
        maxLength: 1,
        cols: 24,
        shopId: shopId,
        setValue: this.detail.monitorCode,
        setFirm: false
      }
    },
    getForm3() {
      let that = this
      return {
        type: 'table',
        showFootButton: false,
        showRowSelect: false,
        showPagination: false,
        // table新增按钮回调
        onAddData: () => {
          return {
            v1: '-',
            v2: '-',
            v3: '-',
            v4: '-',
            v5: '-',
            v6: '-'
          }
        },
        columns: [
          {
            dataIndex: 'salePrice',
            align: 'left',
            title: <div style={{ paddingLeft: '24px' }}>现价</div>,
            width: 200,
            customRender: function(text, records) {
              const onInput = data => {
                if (data.target.value) {
                  if (!/[0-9\.]/.test(data.target.value)) {
                    data.target.value = null
                    that.$message.warning('请输入数字！')
                    return false
                  } else {
                    that.detail.salePrice = data.target.value
                  }
                } else {
                  that.detail.salePrice = ''
                }
              }
              return (
                <a-input
                  disabled={that.detail.canBuy == '0'}
                  type="number"
                  placeholder="价格"
                  addon-before="￥"
                  onChange={onInput}
                  value={text}
                />
              )
            }
          },
          {
            dataIndex: 'vipPrice',
            align: 'left',
            title: '会员最低价',
            width: 200,
            type: 'input',
            customRender: function(text, records) {
              const onInput = data => {
                if (data.target.value) {
                  if (Number(data.target.value) > Number(that.detail.salePrice)) {
                    data.target.value = null
                    that.$message.warning('会员价应低于现价！')
                    return false
                  } else if (!/[0-9\.]/.test(data.target.value)) {
                    data.target.value = null
                    that.$message.warning('请输入数字！')
                    return false
                  } else {
                    that.detail.vipPrice = data.target.value
                  }
                } else {
                  that.detail.vipPrice = ''
                }
              }
              return (
                <a-input
                  disabled={that.detail.canBuy == '0'}
                  type="number"
                  placeholder="会员价（不填没有会员价）"
                  addon-before="￥"
                  onInput={onInput}
                  value={text}
                />
              )
            }
          },
          {
            dataIndex: 'v1',
            align: 'left',
            title: 'V1',
            width: 50
          },
          {
            dataIndex: 'v2',
            align: 'left',
            title: 'V2',
            width: 50
          },
          {
            dataIndex: 'v3',
            align: 'left',
            title: 'V3',
            width: 50
          },
          {
            dataIndex: 'v4',
            align: 'left',
            title: 'V4',
            width: 50
          },
          {
            dataIndex: 'v5',
            align: 'left',
            title: 'V5',
            width: 50
          },
          {
            dataIndex: 'v6',
            align: 'left',
            title: 'V6',
            width: 50
          }
        ],
        dataSource: [
          {
            salePrice: that.detail.salePrice,
            vipPrice: that.detail.vipPrice,
            ...apiTool.getVipPrice(this.detail)
          }
        ]
      }
    },
    getLabelForm(index) {
      return [
        {
          name: '标签名称',
          type: 'input',
          key: 'labelName'
        },
        {
          name: '标签类型',
          type: 'radioButton',
          key: 'publicLabel',
          typeData: [
            {
              name: '店铺私有',
              value: '2'
            },
            {
              name: '公用标签库',
              value: '1'
            }
          ]
        },
        {
          name: '标签分类',
          type: 'iconClass',
          key: 'iconUrl'
        }
      ].filter(e => e)
    },
    getForm4() {
      return {
        type: 'row',
        children: (this.labelGroup || []).map((e, i) => {
          return (
            <CardIconLabelLocal
              data={this.getLabelForm(i)}
              title={e.labelTypeName}
              pubBool={false}
              cols={i == 2 ? 24 : 12}
              style={{
                marginBottom: '10px',
                minHeight: '200px'
              }}
              icons={e.list}
            />
          )
        })
      }
    },
    getForm5() {
      return {
        type: 'cardForm',
        title: '浴室',
        key: 'showerLabel'
      }
    },
    getForm6() {
      return {
        type: 'cardForm',
        title: '房间设施',
        key: 'roomLabel'
      }
    },
    getForm7() {
      return {
        type: 'cardForm',
        cols: 24,
        data: [
          {
            type: 'rich',
            key: 'richText',
            cols: 24
          }
        ]
      }
    },
    getString(str) {
      // eslint-disable-next-line prettier/prettier
      return str
        ? str
            .split(',')
            .filter(e => e)
            .join(',')
        : ''
    },
    getFoot() {
      let that = this
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            let upDown = this.detail.id ? this.detail.upDown : '0'

            if (Number(data.minArea) > Number(data.maxArea)) {
              this.$message.warning('最低面积高于最高面积！')
              return false
            }

            let arr = []
            that.labelGroup.forEach(item =>
              item.list.forEach(e => {
                e['labelTypeCode'] = item.labelTypeCode
                e['labelTypeId'] = item.id
                e['labelTypeName'] = item.labelTypeName
              })
            )
            that.labelGroup.map(e => arr.push(...e.list))
            let obj = Object.assign({
              ...data,
              farmLabelDataDTOS: arr.map(item => ({
                ...item,
                czState: item.czState ? 1 : 0
              }))
            })

            let monitorCode = null
            let monitorImg = null
            if (
              this.detail.monitorCode instanceof Array &&
              this.detail.bannerType == 1 &&
              this.detail.monitorCode.length > 0
            ) {
              monitorCode = (
                this.detail.monitorCode.find(e => e.select) || {
                  monitorCode: ''
                }
              ).monitorCode
              monitorImg = (
                this.detail.monitorCode.find(e => e.select) || {
                  monitorImg: ''
                }
              ).monitorImg
            }

            api.command[this.detail.id ? 'edit' : 'create']
              .call(this, {
                url: '/goods/farmHomeStay',
                params: {
                  ...obj,
                  ...this.detail,
                  foodLabel: this.getString(obj.foodLabel),
                  roomLabel: this.getString(obj.roomLabel),
                  showerLabel: this.getString(obj.showerLabel),
                  upDown: upDown,
                  monitorCode: monitorCode,
                  monitorImg: monitorImg
                },
                isPost: false
              })
              .then(result => {
                this.$router.back()
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.back()
          }
        }
      ]
      const right = [
        {
          name: ` ${this.detail.upDown == 0 ? '下架' : '上架'} `,
          type: 'default',
          onClick: () => {
            getAction(`/api/goods/farmHomeStay/${this.detail.upDown == 0 ? 'down' : 'up'}?id=${this.detail.id}`).then(
              e => {
                if (e.code == 200) {
                  this.$message.success(`${this.detail.upDown == 0 ? '下架成功' : '上架成功'}`)
                  setTimeout(() => {
                    this.$router.back()
                  }, 500)
                } else {
                  this.$message.error(e.msg)
                }
              }
            )
          }
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/goods/farmHomeStay?id=${this.detail.id}`,
              isPost: false
            })
            setTimeout(() => {
              this.$router.back()
            }, 500)
          }
        }
      ]
      return {
        left,
        right: this.detail.id && right
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        foot={this.getFoot()}
        form={this.detail}
        typeData={{
          mainBodyName: this.mainBodyType
        }}
        data={[
          this.getForm1(),
          this.getFormBox(),
          this.isShowSnapshot && this.getForm2Copy(),
          this.getForm3(),
          this.getForm4()
        ]}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
